import { X } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import logoImg from '@/assets/images/logo-black.svg';
import { AppSettings } from '@/constants/AppSettings';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';

export const TopBar = memo(function TopBar() {
  const { t } = useTranslation();
  const { model, themeConfig } = useChatConfig();
  const [close, setUnread] = useBoundStore(state => [
    state.close,
    state.setUnread,
  ]);

  /**
   * Close and clear unread messages.
   */
  const handleClose = () => {
    setUnread(0);
    close();
  };

  return (
    <div className='sticky top-0 flex h-14 flex-row items-center justify-between bg-primary px-4'>
      <div className='flex items-center gap-3'>
        <Image className='h-5' src={themeConfig?.logo ?? logoImg} />
        <div className='inline-flex gap-2 rounded bg-foreground px-2 py-1 text-xs text-background'>
          {t('topBar.beta')}
          {AppSettings.debug && <div className='font-mono'>{model}</div>}
        </div>
      </div>
      <div className='flex gap-4'>
        <button
          type='button'
          className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
          onClick={handleClose}
        >
          <X className='size-6' />
        </button>
      </div>
    </div>
  );
});
