export enum Rating {
  Neutral = 'neutral',
  Positive = 'positive',
  Negative = 'negative',
}

export enum ChatRole {
  User = 'user',
  System = 'system',
  Assistant = 'assistant',
  Tool = 'tool',
  Unknown = 'unknown',
}

export enum Role {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
}

export enum RoleWOSuperAdmin {
  Admin = 'admin',
}

export enum Agent {
  GreenIdea = 'greenidea',
  Rag = 'rag',
}

/**
 * OpenAI: https://platform.openai.com/docs/models/gpt-4o
 * Anthropic: https://docs.anthropic.com/en/docs/about-claude/models#model-comparison-table
 */
export enum ChatModel {
  Claude3Opus = 'claude-3-opus-20240229',
  Claude35Sonnet = 'claude-3-5-sonnet-20240620',
  Claude3Sonnet = 'claude-3-sonnet-20240229',
  Claude3Haiku = 'claude-3-haiku-20240307',
  Gpt4o_2024_08_06 = 'gpt-4o-2024-08-06',
  Gpt4o = 'gpt-4o',
  Gpt4oMini = 'gpt-4o-mini',
  GptO1 = 'gpt-o1',
  GptO1_Mini = 'gpt-o1-mini',
}

export enum DocumentExternalSource {
  Website = 'website',
  Document = 'document',
}
