import { ChatRole } from '@kanbu/schema/enums';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { createMessage, useChat } from '@/hooks/useChat';
import { useDelayedLoading } from '@/hooks/useDelayedLoading';
import { aiCoreApi } from '@/services/aiCoreClient';
import { threadKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { FAQs } from '../faqs/FAQs';
import { InputBox } from '../inputBox/InputBox';
import { MessageBox } from '../message/MessageBox';

export function Chat() {
  const { t } = useTranslation();
  const { chatId, model, embeddingsVersion, chatbotConfig } = useChatConfig();
  const [threadId, setThreadId, opened, setUnread] = useBoundStore(state => [
    state.threadId,
    state.setThreadId,
    state.opened,
    state.setUnread,
  ]);

  const [faqIndex, setFaqIndex] = useState<number | null>(null);

  /**
   * Fetch user threads and create a new thread if it does not exist.
   * This will be used to store the chat history.
   */
  const threadsQuery = useQuery({
    queryKey: threadKeys.chatLists(chatId),
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: async () => {
      const data = await aiCoreApi.threads.findAll(
        {
          chatId,
        },
        /**
         * We don't want to throw an error if the thread is not found
         * This will allow us to create a new thread.
         */
        {
          throwHttpErrors: false,
        },
      );

      if (
        typeof data === 'object' &&
        'statusCode' in data &&
        data.statusCode === 404
      ) {
        const newThread = await aiCoreApi.threads.create({
          chatId,
        });

        return [newThread];
      }

      return data;
    },
    enabled: opened,
  });

  /**
   * Set the thread id to the first thread in the list.
   * This will be used to store the chat history.
   */
  useEffect(() => {
    if (Array.isArray(threadsQuery.data) && threadsQuery.data.length > 0) {
      setThreadId(threadsQuery.data[0].id);
    }
  }, [threadsQuery.data, setThreadId]);

  /**
   * Always set unread, this should be cleaned up when clicking close button.
   */
  const handleMessage = useCallback(() => {
    setUnread(1);
  }, [setUnread]);

  /**
   * This handles the chat logic, it fetches the messages from the API
   * and sends new messages to the API.
   */
  const {
    input,
    handleInputChange,
    messages,
    handleSubmit,
    isLoading,
    isStreaming,
    isInitialized,
    abort,
    insert,
  } = useChat({
    threadId,
    chatId,
    model,
    embeddingsVersion,
    initialMessages: [
      createMessage({
        message: chatbotConfig?.initialMessage || t('messageBox.initial'),
        role: ChatRole.Assistant,
      }),
    ],
    onMessage: handleMessage,
  });

  /**
   * Following two useEffects are used to reset the faqIndex when the threadId changes
   * or when the messages change. This is used to always make sure we show the FAQs
   * after the last AI message upon conversation reset or page reload.
   */
  useEffect(() => {
    setFaqIndex(null);
  }, [threadId]);

  useEffect(() => {
    if (faqIndex === null && messages.length > 0) {
      const lastAiMessageIndex = messages.findLastIndex(
        msg => msg.role === ChatRole.Assistant,
      );

      setFaqIndex(lastAiMessageIndex === -1 ? 0 : lastAiMessageIndex);
    }
  }, [messages, faqIndex]);

  const throttledIsLoading = useDelayedLoading(isLoading, 500);

  return (
    <form className='relative flex h-full flex-col' onSubmit={handleSubmit}>
      <div className='flex grow flex-col-reverse overflow-y-auto px-5 py-4'>
        <div className='flex grow flex-col gap-6 [&>*:first-child]:mt-auto'>
          <AnimatePresence mode='popLayout'>
            {messages.map(({ id, role, text, createdAt, feedback }, index) => (
              <motion.div
                key={id}
                initial={{ y: 40, opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <MessageBox
                  id={id}
                  role={role}
                  text={text}
                  createdAt={createdAt}
                  feedbackId={feedback?.id}
                />
                {index === faqIndex && (
                  <motion.div
                    key='faqs'
                    initial={{ y: 40, opacity: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <FAQs insert={insert} />
                  </motion.div>
                )}
              </motion.div>
            ))}
            {throttledIsLoading && (
              <motion.div
                key='loader'
                initial={{ y: 40, opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <MessageBox key='loader' role={ChatRole.Assistant} isTyping />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className={cn('shrink-0 bg-background-secondary px-5 py-4')}>
        <InputBox
          value={input}
          onChange={handleInputChange}
          onAbort={abort}
          streaming={isStreaming}
          loading={isLoading}
          initializing={!isInitialized}
          maxLength={chatbotConfig?.maxCharacters}
        />
      </div>
    </form>
  );
}
