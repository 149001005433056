import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import type { UseChatReturn } from '@/hooks/chatTypes';
import { aiCoreApi } from '@/services/aiCoreClient';
import { faqKeys } from '@/services/queryClient';

import { FAQItem } from './FAQItem';

export interface FaqsProps {
  insert: UseChatReturn['insert'];
}

export function FAQs({ insert }: FaqsProps) {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { chatId } = useChatConfig();

  const { data } = useQuery({
    queryKey: faqKeys.chatLists(chatId),
    queryFn: async () =>
      aiCoreApi.faqs.findAll({
        chatId,
      }),
  });

  return (
    <div className='flex flex-row flex-wrap gap-2 py-3' ref={ref}>
      {data?.map(faq => (
        <FAQItem
          title={faq.title}
          insert={insert}
          key={faq.id}
          id={faq.id}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
}
