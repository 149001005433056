import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const ActivityIndicator = memo(function ActivityIndicator() {
  const { t } = useTranslation();

  return (
    <div className='flex items-center justify-center gap-2'>
      <span className='sr-only'>{t('glossary.loading')}</span>
      <div className='size-2.5 animate-bounce rounded-full bg-foreground [animation-delay:-0.3s]' />
      <div className='size-2.5 animate-bounce rounded-full bg-foreground [animation-delay:-0.15s]' />
      <div className='size-2.5 animate-bounce rounded-full bg-foreground' />
    </div>
  );
});
