import { cn } from '@utima/ui';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle } from 'lucide-react';
import { memo, type ComponentProps, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';

type BubbleProps = {
  src: string;
  alt?: string;
} & Omit<ComponentProps<'button'>, 'type'>;

const bubbleVariants = {
  hidden: { scale: 0.5, y: 100, opacity: 0 },
  visible: {
    scale: 1,
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
      duration: 0.5,
    },
  },
  exit: {
    scale: 0.5,
    y: 100,
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const popupVariants = {
  hidden: { opacity: 0, y: 20, scale: 0.8 },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 30,
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    scale: 0.8,
    transition: {
      duration: 0.2,
    },
  },
};

export const Bubble = memo(function Bubble({
  src,
  alt = 'Chatbot',
  ...restProps
}: BubbleProps) {
  const { t } = useTranslation();
  const [opened, unread] = useBoundStore(state => [state.opened, state.unread]);
  const [showPopup, setShowPopup] = useState(false);
  const { chatbotConfig, themeConfig } = useChatConfig();

  /**
   * Wait a little bit before showing the popup
   */
  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(true), 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatePresence>
      {!opened && (
        <motion.div
          className='fixed bottom-6 right-6'
          initial='hidden'
          animate='visible'
          exit='exit'
          variants={bubbleVariants}
        >
          <AnimatePresence>
            {showPopup && (
              <motion.div
                variants={popupVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
                className='absolute -top-4 right-14 z-10 flex w-auto flex-col gap-1 rounded-2xl bg-primary px-5 py-3 pr-10 text-primary-fg shadow-md'
              >
                <p className='whitespace-nowrap text-sm'>
                  {chatbotConfig.bubbleTitle || t('bubble.initialTitle')}
                </p>
                <p className='whitespace-nowrap font-bold'>
                  {chatbotConfig.bubbleDescription ||
                    t('bubble.initialSubtitle')}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
          <button
            className='group relative z-20 size-20 rounded-full bg-primary shadow-md'
            type='button'
            {...restProps}
          >
            <div
              className={cn(
                'flex size-full select-none items-center justify-center rounded-full border-2 border-white object-cover overflow-hidden transition-transform group-hover:scale-105 group-active:scale-100',
              )}
            >
              <Image
                alt={alt}
                src={themeConfig?.logoBubble ?? src}
                className='size-fit'
              />
            </div>
            <div
              className={cn(
                'absolute bottom-0 left-0 flex size-8 items-center justify-center rounded-full border-2 border-white text-sm transition-colors',
                {
                  ['bg-red text-white animate-bounce-short']: unread,
                  ['bg-secondary text-secondary-fg']: !unread,
                },
              )}
            >
              {unread || (
                <MessageCircle
                  className='size-[14px] rotate-90 -scale-100'
                  strokeWidth={2.5}
                />
              )}
            </div>
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
