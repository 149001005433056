import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import { RotateCw } from 'lucide-react';
import { memo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

type ResetConversationProps = {
  disabled?: boolean;
  onReset?: () => void;
};

export const ResetConversation = memo(function ResetConversation({
  disabled = false,
  onReset,
}: ResetConversationProps) {
  const { chatId } = useChatConfig();
  const { setThreadId } = useBoundStore(state => ({
    setThreadId: state.setThreadId,
  }));

  const [_, startTransition] = useTransition();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  /**
   * Create new thread to reset the conversation.
   */
  const queryClient = useQueryClient();
  const { mutateAsync: createThread } = useMutation({
    mutationFn: async () => {
      /**
       * Call reset handler so parent component can react, for example
       * aborting current requests to chat.
       */
      onReset?.();

      return aiCoreApi.threads.create({
        chatId,
      });
    },
    onSuccess: data => {
      if (!data?.id) {
        return;
      }

      // Update global state
      setThreadId(data?.id);

      // Invalidate chat query
      queryClient.invalidateQueries({
        queryKey: chatKeys.detail(chatId),
      });
    },
  });

  /**
   * Reset conversation by creating new one and invalidating
   * the chat query storage.
   */
  const handleReset = async () => {
    startTransition(() => {
      setIsLoading(true);
    });

    try {
      await createThread();
    } catch (error) {
      console.error(error);
    } finally {
      startTransition(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <div>
      <button
        type='button'
        className='flex items-center gap-2 text-foreground transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100 disabled:cursor-not-allowed disabled:opacity-75'
        onClick={handleReset}
        disabled={disabled}
      >
        <div className='rounded-full bg-foreground p-1 text-background'>
          <RotateCw
            className={cn('size-4', isLoading && 'animate-spin')}
            strokeWidth={1.5}
          />
        </div>
        <div className='text-xs'>{t('resetConversation')}</div>
      </button>
    </div>
  );
});
