import type { ChatbotConfig, ChatThemeConfig } from '@kanbu/schema';
import { pascalToKebab } from '@kanbu/shared';
import { useQuery } from '@tanstack/react-query';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AppSettings } from '@/constants/AppSettings';
import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys } from '@/services/queryClient';

export interface ChatConfig {
  chatId: string;
  model?: string;
  embeddingsVersion?: number;
  chatbotConfig: ChatbotConfig;
  themeConfig: ChatThemeConfig;
}

export const ChatConfigContext = createContext<ChatConfig | null>(null);

interface ChatConfigProps extends PropsWithChildren {
  chatId: string;
}

/**
 * Fetches config from the server and provides it to the app.
 * Returns no children until the config is loaded.
 */
export function ChatConfigProvider({ chatId, children }: ChatConfigProps) {
  const [config, setConfig] = useState<ChatConfig>();
  const { data } = useQuery({
    queryKey: chatKeys.config(chatId),
    queryFn: () => aiCoreApi.chat.config({ chatId }),
    enabled: !!chatId,
    staleTime: Number.POSITIVE_INFINITY,
  });

  /**
   * Create chat config state when the data are loaded.
   */
  useEffect(() => {
    if (data) {
      let newConfig: ChatConfig = {
        chatId,
        model: undefined,
        embeddingsVersion: undefined,
        chatbotConfig: data.chatbotConfig,
        themeConfig: data.themeConfig ?? {},
      };

      // Parse overrides from sandbox
      if (AppSettings.debug) {
        const url = new URL(window.location.href);
        const overrides = JSON.parse(
          url.searchParams.get('config') || '{}',
        ) as ChatConfig;

        newConfig = {
          ...newConfig,
          ...overrides,
          chatbotConfig: {
            ...newConfig.chatbotConfig,
            ...overrides.chatbotConfig,
          },
          themeConfig: {
            ...newConfig.themeConfig,
            ...overrides.themeConfig,
          },
        };
      }

      /**
       * Find chat root element, web component selector is
       * used in production, fallback to data attribute in dev mode.
       */
      const chatRoot =
        document.querySelector<HTMLDivElement>(
          `kanbu-chatbot[chat="${newConfig.chatId}"]`,
        ) ??
        document.querySelector<HTMLDivElement>(
          `[data-kanbu-chat="${newConfig.chatId}"]`,
        );

      // Set CSS variable overrides from config
      if (chatRoot) {
        Object.entries(newConfig.themeConfig).forEach(([key, value]) => {
          if (value) {
            chatRoot.style.setProperty(
              `--kanbu-${pascalToKebab(key)}`,
              value.toString(),
            );
          }
        });
      }

      setConfig(newConfig);
    }

    if (!data) {
      setConfig(undefined);
    }
  }, [chatId, data]);

  // Don't render anything until the config is loaded
  if (!config) {
    return null;
  }

  return (
    <ChatConfigContext.Provider value={config}>
      {children}
    </ChatConfigContext.Provider>
  );
}

/**
 * Hook for accessing chat config.
 */
export function useChatConfig() {
  const context = useContext(ChatConfigContext);

  if (!context) {
    throw new Error('Missing ChatConfigContext.Provider in the tree');
  }

  return context;
}
